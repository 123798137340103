<template>
  <b-card class="shop-delivery-settings">
    <div class="row">
      <div class="cols-12">
        <b-form-group>
          <b-form-input
            style="width: 145px;"
            v-model="searchInput"
            :placeholder="$t('Search')"
          />
        </b-form-group>
      </div>
    </div>
    <b-tabs
      pills
      vertical
      class="user-profile-info justify-content-center mb-5"
      @activate-tab="tabChanged"
    >
      <b-tab
        v-if="canShowTab('General')"
        active
      >
        <template #title>
          <span class="d-inline">{{ $t('General') }}</span>
        </template>
        <b-row v-if="storePricing">
          <!-- <b-col
            cols="12"
            md="6"
          >
            <b-form-group label="">
              <label
                class="form-extra-label"
              >{{ $t('Base Price') }} <span>({{ $t('Your delivery charge base price') }})</span></label>
              <b-form-input
                v-model="storePricing.delivery_charge"
                :class="{ 'is-invalid': !storePricing.delivery_charge && isDataSubmitted }"
                type="text"
                @keypress="$superApp.validations.numberOnly"
              />
            </b-form-group>
          </b-col> -->
          <!-- <b-col
            cols="12"
            md="6"
          >
            <b-form-group label="">
              <label
                class="form-extra-label"
              >{{ $t('Base Distance') }} <span>({{ $t('Distance covered under base price') }})</span></label>
              <b-form-input
                v-model="storePricing.base_distance"
                :class="{ 'is-invalid': !storePricing.base_distance && isDataSubmitted }"
                type="text"
                @keypress="$superApp.validations.numberOnly"
              />
            </b-form-group>
          </b-col> -->
          <!-- <b-col
            cols="12"
            md="6"
          >
            <b-form-group label="">
              <label
                class="form-extra-label"
              >{{ $t('Unit Distance Pricing') }} <span>({{ $t('Charge for every additional KM more than base distance') }})</span></label>
              <b-form-input
                v-model="storePricing.distance_price"
                :class="{ 'is-invalid': !storePricing.distance_price && isDataSubmitted }"
                type="text"
                @keypress="$superApp.validations.numberOnly"
              />
            </b-form-group>
          </b-col> -->
          <b-col
            v-if="storePricing.settings"
            cols="12"
            md="6"
          >
            <b-form-group label="">
              <label
                class="form-extra-label"
              >{{ $t('Service Radius') }} <span>({{ $t('Your delivery area radius') }})</span></label>
              <b-form-input
                v-model="storePricing.settings.service_radius"
                :class="{ 'is-invalid': !storePricing.settings.service_radius && isDataSubmitted }"
                type="text"
                @keypress="$superApp.validations.numberOnly"
              />
            </b-form-group>
          </b-col>
          <!-- <b-col
            v-if="storePricing"
            cols="12"
            md="6"
          >
            <b-form-group label="">
              <label class="form-extra-label">{{ $t('Estimated Delivery Time') }}</label>
              <b-form-input
                v-model="storePricing.estimated_delivery_time"
                type="number"
                @keypress="$superApp.validations.numberOnly"
              />
            </b-form-group>
          </b-col> -->
          <b-col
            v-if="storePricing"
            cols="12"
            md="6"
          >
            <b-form-group label="">
              <label
                class="form-extra-label"
              >{{ $t('Default Weight') }} <span>({{ $t('Set the default weight of the package') }})</span></label>
              <b-form-input
                v-model="storePricing.default_weight"
                type="number"
                @keypress="$superApp.validations.numberOnly"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="storePricing.settings"
            cols="12"
            md="4"
          >
            <b-form-group label="">
              <label
                class="form-extra-label"
              >{{ $t('Agent Order Limit') }} <span>({{ $t('No of active orders for agent') }})</span></label>
              <b-form-input
                v-model="storePricing.settings.agent_limit"
                type="number"
                @keypress="$superApp.validations.numberOnly"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="storePricing.settings"
            cols="12"
            md="4"
            class="d-flex align-items-center"
          >
            <b-form-checkbox
              v-model="storePricing.settings.force_assign"
              name="force_assign"
              switch
              inline
            >
              <label
                class="form-extra-label"
              >{{ $t('Force Assign') }} <span>({{ $t('Auto force assign orders to your drivers') }})</span></label>
            </b-form-checkbox>
          </b-col>
          <b-col
            v-if="storePricing.settings"
            cols="12"
            md="4"
            class="d-flex align-items-center"
          >
            <b-form-checkbox
              v-model="storePricing.settings.own_agents"
              name="own_agents"
              switch
              inline
            >
              <label
                class="form-extra-label"
              >{{ $t('Own Agents') }} <span>({{ $t('Use your store drivers for delivery') }})</span></label>
            </b-form-checkbox>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group :label="$t('Pricing')">
              <b-form-radio-group v-model="storePricing.settings.courier_pricing">
                <b-form-radio value="0">
                  <span>Store Pricing</span>
                </b-form-radio>
                <b-form-radio value="1">
                  <span>Courier Pricing</span>
                </b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="12"
            class="text-center"
          >
            <b-button
              variant="primary"
              @click="saveSettings"
            >
              {{ $t('Save Settings') }}
            </b-button>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab v-if="canShowTab('Store Delivery')">
        <template #title>
          <span class="d-inline">{{ $t('Store Delivery') }}</span>
        </template>
        <b-row v-if="storePricing">
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group>
              <label
                class="form-extra-label"
              >{{ $t('Pricing Type') }} <span>({{ $t('Select Bucket or Linear Pricing') }})</span></label>
              <b-form-select
                v-model="storePricing.price_type"
                :options="priceTypes"
                @change="pricingChange"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center"
          >
            <b-form-checkbox
              v-model="pricingModeApi"
              switch
              inline
              disabled
            >
              <span>{{ pricingModeApi ? $t('Bucket Pricing') : $t('Linear Pricing') }} ({{ $t('Pricing set currently for the Shop') }})</span>
            </b-form-checkbox>
          </b-col>
        </b-row>
        <div
          v-if="storePricing && storePricing.price_type === 'BUCKET'"
          class="row"
        >
          <div class="col-md-12">
            <div
              v-for="(item, index) of bucketPricings"
              :key="index"
              class="row"
            >
              <div class="col-md-2">
                <b-form-group label="">
                  <label class="form-extra-label">{{ $t('Base Price') }}</label>
                  <b-form-input
                    v-model="item.price"
                    type="number"
                    @keypress="$superApp.validations.numberOnly"
                  />
                </b-form-group>
              </div>
              <div class="col-md-2">
                <b-form-group label="">
                  <label
                    class="form-extra-label"
                  >{{ $t('Unit') }} <span>({{ $t('Kg/Km') }})</span></label>
                  <b-form-select
                    v-model="item.unit"
                    :options="unitTypes"
                    :disabled="index != 0"
                    @change="unitChanged"
                  />
                </b-form-group>
              </div>
              <div class="col-md-3">
                <b-form-group label="">
                  <label
                    class="form-extra-label"
                  >{{ item.unit == 'KG' ? $t('Weight from') : $t('Kilometer from') }} <span>({{ item.unit }})</span></label>
                  <b-form-input
                    v-if="item.unit == 'KG'"
                    v-model="item.from_kg"
                    type="number"
                    @keypress="$superApp.validations.numberOnly"
                  />
                  <b-form-input
                    v-else
                    v-model="item.from_km"
                    type="number"
                    @keypress="$superApp.validations.numberOnly"
                  />
                </b-form-group>
              </div>
              <div class="col-md-2">
                <b-form-group label="">
                  <label
                    class="form-extra-label"
                  >{{ item.unit == 'KG' ? $t('Weight to') : $t('Kilometer to') }} <span>({{ item.unit }})</span></label>
                  <b-form-input
                    v-if="item.unit == 'KG'"
                    v-model="item.to_kg"
                    type="number"
                    @keypress="$superApp.validations.numberOnly"
                  />
                  <b-form-input
                    v-else
                    v-model="item.to_km"
                    type="number"
                    @keypress="$superApp.validations.numberOnly"
                  />
                </b-form-group>
              </div>
              <div class="col-md-3">
                <div class="row">
                  <div class="col-8">
                    <b-form-group label="">
                      <label class="form-extra-label">{{ item.unit == 'KG' ? 'KM' : 'KG' }} {{ $t('Included') }}</label>
                      <b-form-input
                        v-if="item.unit == 'KG'"
                        v-model="item.to_km"
                        type="number"
                        @keypress="$superApp.validations.numberOnly"
                      />
                      <b-form-input
                        v-else
                        v-model="item.to_kg"
                        type="number"
                        @keypress="$superApp.validations.numberOnly"
                      />
                    </b-form-group>
                  </div>
                  <div class="col-4 d-flex align-items-center p-0">
                    <button
                      v-if="bucketPricings.length - 1 == index"
                      class="btn btn-primary mr-1 px-1"
                      @click="addBucketList"
                    >
                      <feather-icon icon="PlusIcon" />
                    </button>
                    <button
                      v-if="bucketPricings.length != 1"
                      class="btn btn-primary px-1"
                      @click="removeBucketList(index)"
                    >
                      <feather-icon icon="MinusIcon" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <b-col
            cols="12"
            md="12"
            class="text-center"
          >
            <b-button
              variant="primary"
              @click="saveSettings"
            >
              {{ $t('Save Settings') }}
            </b-button>
          </b-col>
        </div>
        <div
          v-if="storePricing && storePricing.price_type === 'LINEAR'"
          class="row"
        >
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-2">
                <b-form-group label="">
                  <label class="form-extra-label">{{ $t('Base Price') }}</label>
                  <b-form-input
                    v-model="storePricing.delivery_charge"
                    type="number"
                    @keypress="$superApp.validations.numberOnly"
                  />
                </b-form-group>
              </div>
              <div class="col-md-2">
                <b-form-group label="">
                  <label
                    class="form-extra-label"
                  >{{ $t('Unit') }} <span>({{ $t('Kg/Km') }})</span></label>
                  <b-form-select
                    v-model="storePricing.unit"
                    :options="unitTypes"
                  />
                </b-form-group>
              </div>
              <div class="col-md-2">
                <b-form-group label="">
                  <label class="form-extra-label">{{ storePricing.unit == 'KG' ? $t('KG Included') : $t('KM Included') }}</label>
                  <b-form-input
                    v-model="storePricing.base_distance"
                    value="0"
                    type="number"
                    @keypress="$superApp.validations.numberOnly"
                  />
                </b-form-group>
              </div>
              <div class="col-md-2">
                <b-form-group label="">
                  <label
                    class="form-extra-label"
                  >{{ $t('Price per additional') }} <span>({{ storePricing.unit }})</span></label>
                  <b-form-input
                    v-model="storePricing.distance_price"
                    type="number"
                    @keypress="$superApp.validations.numberOnly"
                  />
                </b-form-group>
              </div>
              <div class="col-md-3">
                <b-form-group label="">
                  <label class="form-extra-label">{{ storePricing.unit == 'KG' ? $t('Distance Included (KM)') : $t('Weight Included (KG)') }}</label>
                  <b-form-input
                    v-model="storePricing.extra"
                    type="number"
                    @keypress="$superApp.validations.numberOnly"
                  />
                </b-form-group>
              </div>
            </div>
          </div>
          <b-col
            cols="12"
            md="12"
            class="text-center"
          >
            <b-button
              variant="primary"
              @click="saveSettings"
            >
              {{ $t('Save Settings') }}
            </b-button>
          </b-col>
        </div>
      </b-tab>
      <template v-for="(companyCourierName, index) of courierCompanyList">
        <b-tab
          v-if="canShowTab(companyCourierName)"
          :key="index"
        >
          <template
            #title
            @click="tabChanged(index + 2)"
          >
            <span
              class="d-inline"
              style="text-transform: capitalize"
            >{{ companyCourierName.toLowerCase() }}</span>
          </template>
          <div class="wrap-stripe-config">
            <b-row>
              <!-- <b-col
              cols="12"
              md="6"
            >
              <b-form-group :label="$t('Courier Company')">
                <b-form-select
                  v-model="courierCompanyName"
                  @change="courierCompanyById"
                >
                  <option value="">
                    Select Company
                  </option>
                  <option
                    v-for="(item, index) of courierCompanyList"
                    :key="index"
                    :value="item"
                  >
                    {{ item }}
                  </option>
                </b-form-select>
              </b-form-group>
            </b-col> -->
              <b-col
                cols="12"
                md="6"
              >
                
                <b-form-group :label="$t('Status')">
                  <b-form-radio-group
                    v-model="courierDetail.status"
                    :options="courierDetailOptions"
                    class="mb-3"
                    value-field="value"
                    text-field="text"
                    disabled-field="notEnabled"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group :label="$t('Login')">
                  <b-form-input
                    v-model="courierDetail.login"
                    type="text"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group :label="$t('Password')">
                  <b-form-input
                    v-model="courierDetail.password"
                    type="password"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group :label="$t('Pickup Location')">
                  <b-input-group>
                    <b-form-input
                      v-model="courierDetail.pickup"
                      type="text"
                      disabled
                    />
                    <b-input-group-append>
                      <button
                        class="btn btn-primary"
                        @click="getShipRocketPickup"
                      >
                        {{ $t('Update') }}
                      </button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <div class="col-md-12 mt-3 text-center">
                <button
                  class="btn btn-primary mx-1"
                  @click="saveCourierDetails"
                >
                  {{ $t('Save') }}
                </button>
              </div>
            </b-row>
          </div>
        </b-tab>
      </template>
    </b-tabs>
    <b-modal
      v-model="showSendPickup"
      :no-close-on-backdrop="true"
      ok-only
      :ok-title="$t('Save Changes')"
      hide-footer
      :title="$t('Select Pickup Locations')"
    >
      <pickup-address
        v-for="(item, index) of pickupLocations"
        :key="index"
        :address="item"
        :selected-address="courierDetail.pickup"
        @deliver-here="useThisPickup"
      />
      <modal-footer
        :show-cancel="false"
        @ok="sendPickupLocation"
      />
    </b-modal>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BFormGroup, BInputGroup, BInputGroupAppend, BModal, BFormInput, BButton, BFormCheckbox, BTab, BTabs, BFormSelect, BFormRadioGroup, BFormRadio } from 'bootstrap-vue'
import { hideLoader, showDangerNotification, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import ModalFooter from '@/@core/components/modal-footer/ModalFooter.vue'
import { useStoreUi } from './useStore'
import PickupAddress from './shared/PickupAddress.vue'

export default {
  components: {
    BCard,
    ModalFooter,
    BModal,
    BRow,
    BFormRadioGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormRadio,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BFormCheckbox,
    BTab,
    BTabs,
    BFormSelect,
    PickupAddress,
  },
  data() {
    return {
      searchInput: '',
      userStoreType: null,
      storePricing: null,
      pricingModeApi: false,
      isDataSubmitted: false,
      courierCompanyList: [],
      courierCompanyName: '',
      courierDetail: {},
      showSendPickup: false,
      pickupLocations: [],
      bucketPricings: [],
      priceTypes: [
        {
          text: 'Bucket Pricing',
          value: 'BUCKET',
        },
        {
          text: 'Linear Pricing',
          value: 'LINEAR',
        },
      ],
      unitTypes: [
        {
          text: 'Kg',
          value: 'KG',
        },
        {
          text: 'Km',
          value: 'KM',
        },
      ],
    }
  },
  computed: {
    storeInfo() {
      return window.SuperApp.getters.userInfo()
    },
    courierDetailOptions: {
      get(){
        const opts = this.$superApp.appConstants.statusList().filter(i => i.value != '')
        return opts
      }
    }
  },
  mounted() {
    this.getShopType()
    this.courierCompany()
  },
  methods: {
    useThisPickup(address) {
      this.courierDetail.pickup = address.pickup_location
    },
    unitChanged() {
      const { unit } = this.bucketPricings[0]
      this.bucketPricings.forEach(x => {
        // eslint-disable-next-line no-param-reassign
        x.unit = unit
      })
    },
    canShowTab(tabName) {
      if (!this.searchInput) return true
      return tabName.toLowerCase().includes(this.searchInput.toLowerCase())
    },
    tabChanged(tabIndex) {
      if (tabIndex >= 2) {
        this.courierCompanyName = this.courierCompanyList[tabIndex - 2]
        this.courierCompanyById()
      }
    },
    pricingChange() {
      if (this.bucketPricings.length === 0) {
        this.addBucketList()
      }
    },
    addBucketList() {
      this.bucketPricings.push({
        from_kg: 0,
        to_kg: 0,
        from_km: 0,
        to_km: 0,
        price: 0,
        unit: this.bucketPricings[0] ? this.bucketPricings[0].unit : this.storePricing.unit,
      })
    },
    removeBucketList(index) {
      this.bucketPricings = this.bucketPricings.filter((_, i) => i !== index)
    },
    courierCompany($event = '') {
      const { courierCompany } = useStoreUi()

      courierCompany($event ? `?company=${$event}` : '')
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.courierCompanyList = data.responseData
          } else {
            showDangerNotification(this, data.message)
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    sendPickupLocation() {
      this.showSendPickup = false
      this.saveCourierDetails()
    },
    getShipRocketPickup() {
      const { shipRocketPickup } = useStoreUi()
      showLoader()
      shipRocketPickup(`login=${this.courierDetail.login}&password=${this.courierDetail.password}`)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            if (Array.isArray(data.responseData.pickups)) {
              this.pickupLocations = data.responseData.pickups
              this.showSendPickup = true
            }
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    saveCourierDetails() {
      // if (!this.courierDetail || !this.courierCompanyName) {
      //   return
      // }
      if (Object.keys(this.courierDetail).length === 0 || !this.courierCompanyName) {
        showDangerNotification(this, 'Please enter all values')
        return
      }
      this.courierDetail.company = this.courierCompanyName
      this.courierDetail.store_id = window.SuperApp.getters.userInfo().id
      const { saveCourierCompany } = useStoreUi()
      showLoader()
      saveCourierCompany(this.courierDetail)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.courierDetail = data.responseData
            if (!this.courierDetail) {
              this.courierDetail = {}
            }
            if (!this.courierDetail.pickup && this.courierDetail.shiprocket_info && this.courierDetail.shiprocket_info.pickups) {
              this.pickupLocations = this.courierDetail.shiprocket_info.pickups
              this.showSendPickup = true
            }
            showSuccessNotification(this, data.message ? data.message : 'Settings saved successfully')
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    courierCompanyById() {
      if (!this.courierCompanyName) {
        this.courierDetail = {}
        return
      }

      const { courierCompany } = useStoreUi()
      showLoader()
      courierCompany(`?company=${this.courierCompanyName}`)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.courierDetail = data.responseData[this.courierCompanyName]
            if (!this.courierDetail) {
              this.courierDetail = {}
            }
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    getShopType() {
      const { getShopPrice } = useStoreUi()
      showLoader()
      getShopPrice()
        .then(({ data }) => {
          this.userStoreType = data.responseData
          this.shopPricingInfo()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    shopPricingInfo() {
      if (!this.userStoreType) {
        hideLoader()
        return
      }
      const { shopPricingInfo } = useStoreUi()
      showLoader()
      shopPricingInfo(`${this.storeInfo.id}/${this.storeInfo.city_id}/${this.storeInfo.store_type_id}`)
        .then(({ data }) => {
          hideLoader()
          this.storePricing = data.responseData
          this.storePricing.settings.force_assign = Boolean(this.storePricing.settings.force_assign)
          this.storePricing.settings.own_agents = Boolean(this.storePricing.settings.own_agents)
          this.pricingModeApi = this.storePricing.price_type === 'BUCKET'
          if (this.storePricing.bucket_prices) {
            const pricing = JSON.parse(this.storePricing.bucket_prices)
            if (pricing.length === 0) {
              if (this.storePricing.price_type === 'BUCKET') {
                this.bucketPricings = []
                this.addBucketList()
              }
            } else {
              const mapPricing = pricing.map(x => ({
                ...x,
                unit: x.unit ? x.unit : this.storePricing.unit,
              }))
              this.bucketPricings = mapPricing
            }
          } else {
            this.bucketPricings = []
            this.addBucketList()
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    saveSettings() {
      this.isDataSubmitted = true
      setTimeout(() => {
        if (window.SuperApp.validations.anyErrorInModal(false)) {
          return
        }
        this.isDataSubmitted = false
        const formData = {}
        formData.price_type = this.storePricing.price_type
        formData.delivery_charge = this.storePricing.delivery_charge
        formData.bucket = this.bucketPricings
        formData.base_distance = this.storePricing.base_distance
        formData.unit = this.storePricing.unit
        formData.extra = this.storePricing.extra
        formData.default_weight = this.storePricing.default_weight
        formData.estimated_delivery_time = this.storePricing.estimated_delivery_time ? this.storePricing.estimated_delivery_time : 0
        formData.distance_price = this.storePricing.distance_price
        formData.store_type_id = this.userStoreType.id
        formData.agent_limit = this.storePricing.settings.agent_limit
        formData.courier_pricing = this.storePricing.settings.courier_pricing
        formData.service_radius = this.storePricing.settings.service_radius
        formData.force_assign = Number(this.storePricing.settings.force_assign)
        formData.own_agents = Number(this.storePricing.settings.own_agents)
        formData.store_id = this.storeInfo.id
        formData.city_id = this.storeInfo.city_id
        formData.country_id = this.storeInfo.country_id
        if (this.storePricing.id) {
          formData.id = this.storePricing.id ? this.storePricing.id : 0
        }
        const { saveDeliverySettings } = useStoreUi()
        showLoader()
        saveDeliverySettings(formData)
          .then(({ data }) => {
            hideLoader()
            if (data.statusCode === '200') {
              showSuccessNotification(this, data.message)
              this.shopPricingInfo()
            } else {
              showDangerNotification(this, data.message)
            }
          })
          .catch(error => {
            showErrorNotification(this, error)
          })
      })
    },
  },
}
</script>

<style scoped>
.button-type-style {
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  font-weight: 300;
  cursor: pointer;
}

.button-type-style.active {
  background: #7367f0 !important;
}

.button-type-style.active label {
  color: #fff;
}
</style>

<style lang="scss">
.shop-delivery-settings .user-profile-info ul.nav.nav-pills {
  justify-content: center;
}

.shop-delivery-settings ul .nav-item a {
  justify-content: flex-start;

  @media only screen and (max-width: 767px) {
    justify-content: center;
  }
}

.shop-delivery-settings {
  .user-profile-info {
    .col-auto {
      border-right: 3px solid #d8d6de;
    }
  }

  @media only screen and (max-width: 767px) {
    .user-profile-info {
      display: block;
      .col-auto {
        border-right: 0;
      }
    }
  }
}
</style>
